import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import { getVisitasDias } from '../../service/api';
import Cookies from 'js-cookie';
import { HeaderAdmin } from './headerAdmin';
import { AsideAdmin } from './asideAdmin';
import { Line } from 'react-chartjs-2';
import { Acessos } from '../../shared/types';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

export const HomeAdmin = () => {
    const navigate = useNavigate()
    const [acessos, setAcessos] = useState<Acessos[]>([])
    const labels = acessos?.map((item) => item.dia);
    const dados = acessos?.map((item) => item.acessos);

    useEffect(()=> {
        const loginCookies = Cookies.get('token');
        if(loginCookies === undefined) {
            navigate('/admin')
        }
        return
    },[navigate])

    const buscaAcessos = async () => {
        try {
            const acessos = await getVisitasDias()
            setAcessos(acessos?.data)
        } catch (error) {
            //
        }
    }
    useEffect(() => {
        buscaAcessos();
    },[])

    const data = {
        labels: labels,
        datasets: [
          {
            label: 'Acessos por Dia',
            data: dados,
            fill: false,
            borderColor: "rgb(75, 192, 192)",
            tension: 0.1
          },
        ],
      };

    return (
        <div>
            <div className='flex flex-col h-full fixed w-full z-0'>
                <HeaderAdmin />
                <AsideAdmin />
            </div>
            <div className='p-28'>
                <div className='flex items-center gap-3'>
                    <i className="ri-dashboard-3-line ri-3x"></i>
                    <h1 className='font-semibold text-2xl'>Painel</h1>
                </div>
                <hr className='w-full h-px' />
                <div className='flex mt-5 items-center gap-2 ml-5 mb-2'>
                    <i className="ri-line-chart-line ri-2x"></i>
                    <h3 className='text-lg font-semibold'>Visitas dos últimos dias</h3>
                </div>
                <div className='rounded-lg p-5 border-slate-400 border-8 z-10 relative'>
                    <Line style={{maxHeight: 300}} data={data}/>
                </div>
            </div>
        </div>
    )
}