import { useNavigate } from "react-router-dom"
import { Veiculo } from "../shared/types"
import { Telefone as TelefoneDictionary, Veiculo as VeiculoDictionary } from "../shared/utils/dictionary"
import { ModalProposta } from "./modalProposta"
import semImagem from '../assets/semImagem.webp'
import { useContext } from "react"
import { AuthContext } from "../shared/context/auth"
import { postWhats } from "../service/api"
import { isMobile } from "../shared/utils/javascript"

type CardVeiculoType = {
    veiculo: Veiculo
}

export const CardVeiculo = ({ veiculo }: CardVeiculoType) => {

    const { telefone, email } = useContext(AuthContext);
    const navigate = useNavigate();

    const img = `https://litoralcar.com.br/foto-resize-site/X/${veiculo?.[VeiculoDictionary.COD_VEICULO]}/${process.env.REACT_APP_COD_LOJA}/${veiculo?.[VeiculoDictionary.FOTOS][0]}`

    const cambio = veiculo[VeiculoDictionary.OPCIONAIS].find(x => x.codigo === 21)

    const handleNavigate = () => {
        const paramsObj = { 
            cod_veiculo: veiculo[VeiculoDictionary.COD_VEICULO],
            marca: veiculo[VeiculoDictionary.MARCA],
            modelo: veiculo[VeiculoDictionary.MODELO],
            versao: veiculo[VeiculoDictionary.VERSAO]
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/veiculo?params=${paramsString}`;
        navigate(urlComObjeto)
    }

    return (
        <div className="flex flex-col bg-secondary text-accent mobile:min-w-[95vw] rounded justify-between" style={{boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.25)"}}>
            <ModalProposta veiculo={veiculo} />
            {veiculo[VeiculoDictionary.FOTOS].length === 0 ? (
                <div className="relative pt-[75%] w-full">
                    <img src={semImagem} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover absolute w-full rounded-t h-full top-0" onClick={handleNavigate} />
                </div>
            ) : (
                <div className="relative pt-[75%] w-full">
                    <img src={img} alt={veiculo[VeiculoDictionary.VEICULO]} className="cursor-pointer object-cover absolute w-full rounded-t h-full top-0" onClick={handleNavigate} />
                </div>
            )}
            <div className="p-4 flex flex-col gap-3 w-full">
                <h2 className="flex flex-col text-[#777777] text-sm"><span className="font-semibold text-black text-lg">{veiculo[VeiculoDictionary.MARCA]} {veiculo[VeiculoDictionary.MODELO]}</span>{veiculo[VeiculoDictionary.VERSAO]}</h2>
                <div className="grid grid-cols-2">
                    <div>
                        <h2 className="text-[#777]"><i className="ri-calendar-2-line text-black"></i> {veiculo[VeiculoDictionary.ANO] ?? "-"}</h2>
                    </div>
                    <div className="flex flex-col items-end">
                        <h2 className="text-[#777]"><i className="ri-dashboard-3-line text-black"></i> {Number(veiculo[VeiculoDictionary.QUILOMETRAGEM]).toLocaleString()}Km</h2>
                    </div>
                </div>
                <div className="flex justify-between">
                    <button onClick={handleNavigate} className="btn btn-primary h-8 text-white px-10 min-h-0">
                        {veiculo[VeiculoDictionary.VALOR].toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL'
                        })}
                    </button>
                    <div className="flex gap-2 items-center">
                        <i onClick={() => {
                                window.open(`mailto:${email}`, "_blank")
                            }} className="ri-mail-line cursor-pointer border border-black rounded-full p-1 h-7 w-7 items-center flex justify-center"></i>
                    </div>
                </div>
            </div>
        </div>
    )
}