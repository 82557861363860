import { useNavigate } from 'react-router-dom'
import Logo from '../assets/logo.webp'
import { isMobile } from '../shared/utils/javascript';
import Select from 'react-select';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../shared/context/auth';
import { postWhats } from '../service/api';
import { Telefone as TelefoneDictionary } from '../shared/utils/dictionary';
import { Endereco } from '../shared/utils/constants';

export const Header = () => {

    const navigate = useNavigate();
    const { estoque, telefone, ip } = useContext(AuthContext)
    const [optionsMarcaModelo, setOptionsMarcaModelo] = useState([{value: "Busque por marca ou modelo", label: "Busque por marca ou modelo" }]);

    const telefoneWhats = telefone.find((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.find((x) => x.whatsapp === "0")
    const verifyRoute = (path: string) => {
        if(path === window.location.pathname) return true
    }
    
    const customStyles = {
        control: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '0px',
            maxWidth: '600px',
            height: '40px',
            paddingLeft: '10px',
            minWidth: '400px'
        }),
    };
    const customStylesMobile = {
        control: (provided: any) => ({
            ...provided,
            cursor: 'pointer',
            borderRadius: '0px',
            minWidth: '80vw',
            height: '40px',
            paddingLeft: '10px'
        }),
    };

    const handleSelectChange = (selectedOption: any) => {
        const paramsObj = { 
            busca: selectedOption.value,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    useEffect(() => {
        const marca = estoque.map((x) => {
            return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        });
        const marcaResult = listaMarca.map((x) => {
            return {value: x, label: x }
        })
    
        const modelo = estoque.map((x) => {
            return x.modelo
        })
        const listaModelo = modelo.filter((element, index) => {
            return modelo.indexOf(element) === index;
        });
        const modeloResult = listaModelo.map((x) => {
            return {value: x, label: x }
        })
        setOptionsMarcaModelo([...marcaResult, ...modeloResult])
    },[estoque])

    return (
        <div className='bg-black pb-3'>
            <div className="sticky top-0 bg-black px-3 py-5 flex items-center gap-12 z-50 mobile:px-3 mobile:py-3 justify-center">
                <img className='cursor-pointer object-cover w-44 h-full' onClick={() => navigate('/')} src={Logo} alt="Logo" />
                {isMobile() ? null : (
                <div className='text-white flex items-center gap-12'>
                    <h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2>
                    <h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2>
                    <h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2>
                    <h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2>
                    <h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2>
                    <h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Avaliação</h2>
                </div>
                )}
            </div>
            <div className='bg-[#cccccc] py-2 flex items-center justify-evenly mobile:hidden'>
                <div className='flex gap-3 items-center'>
                    <h3 className='font-semibold'><i className="ri-whatsapp-line ri-lg font-normal"></i> <span onClick={() => {
                        postWhats({
                            ip: ip,
                            dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                            codVeiculo: 0
                        })
                        window.open(`https://api.whatsapp.com/send?phone=+55${telefoneWhats?.[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                    }} className='cursor-pointer'>{telefoneWhats?.telefone}</span> <span className='font-bold text-xl'>|</span> {telefoneRamal?.telefone}</h3>
                    <hr className='h-8 w-1 bg-black text-black' />
                    <h3 className='font-semibold'><i className="ri-map-pin-2-line ri-lg font-normal"></i> {Endereco.endereco}</h3>
                </div>
                <Select
                    styles={isMobile() ? customStylesMobile : customStyles}
                    aria-labelledby="Montserrat"
                    inputId="Montserrat"
                    name="select-marca-modelo"
                    placeholder="Busque por marca ou modelo"
                    onChange={handleSelectChange}
                    aria-label="Busque por marca ou modelo"
                    options={optionsMarcaModelo}
                />
            </div>
            <div className='desktop:hidden flex items-center bg-[#cccccc] py-1 w-full justify-center'>
            <div className="dropdown">
                <div tabIndex={0} role="button" aria-label="Hamburguer" className="btn outline-none border-none text-xl text-[#333333]">{window.location.pathname === "/" ? "HOME" : window.location.pathname.toUpperCase().replace(/\//g, "")}<i className="ri-menu-line ri-lg"></i></div>
                <ul tabIndex={0} className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-max">
                <li aria-label="Início"><h2 onClick={() => navigate('/')} className={`cursor-pointer ${verifyRoute('/') ? "font-semibold" : ""}`}>Início</h2></li>
                <li aria-label="Sobre nós"><h2 onClick={() => navigate('/sobre-nos')} className={`cursor-pointer ${verifyRoute('/sobre-nos') ? "font-semibold" : ""}`}>Sobre nós</h2></li>
                <li aria-label="Estoque"><h2 onClick={() => navigate('/estoque')} className={`cursor-pointer ${verifyRoute('/estoque') ? "font-semibold" : ""}`}>Estoque</h2></li>
                <li aria-label="Financiamento"><h2 onClick={() => navigate('/financiamento')} className={`cursor-pointer ${verifyRoute('/financiamento') ? "font-semibold" : ""}`}>Financiamento</h2></li>
                <li aria-label="Contato"><h2 onClick={() => navigate('/contato')} className={`cursor-pointer ${verifyRoute('/contato') ? "font-semibold" : ""}`}>Contato</h2></li>
                <li aria-label="Avaliação"><h2 onClick={() => navigate('/avaliacao')} className={`cursor-pointer ${verifyRoute('/avaliacao') ? "font-semibold" : ""}`}>Avaliação</h2></li>
                </ul>
            </div>
            </div>
        </div>
    )
}