import { Carousel } from "flowbite-react";
import { useContext, useEffect, useState } from "react";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { Slider } from "../shared/types";
import { FiltroHome } from "../components/filtroHome";
import { CardVeiculo } from "../components/cardVeiculo";
import { useNavigate } from "react-router-dom";
import avalie from '../assets/avalie.webp';
import financie from '../assets/finan.webp';
import { buscarSlide } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { Endereco } from "../shared/utils/constants";
const Home: React.FC = () => {
    const navigate = useNavigate()
    const { estoque } = useContext(AuthContext)
    const [slide, setSlide] = useState<Slider[]>([]);
    const [load, setLoad] = useState<boolean>(false);
    const estilos = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "bg-accent hover:bg-white dark:bg-gray-800/50 dark:hover:bg-gray-800 hidden",
            on: "bg-primary dark:bg-primary hidden"
          },
          base: "h-2 w-2 rounded-full",
          wrapper: "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-white group-hover:bg-white/80 group-focus:outline-none group-focus:ring-4 group-focus:ring-white dark:bg-accent/30 dark:group-hover:bg-accent/60 dark:group-focus:ring-accent/70 sm:h-10 sm:w-10",
          icon: "h-5 w-5 text-primary dark:text-primary sm:h-6 sm:w-6"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const estilosHiddenControls = {
        root: {
          base: "relative h-full w-full",
          leftControl: "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
          rightControl: "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none"
        },
        indicators: {
          active: {
            off: "hidden",
            on: "hidden"
          },
          base: "hidden",
          wrapper: "hidden"
        },
        item: {
          base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
          wrapper: {
            off: "w-full flex-shrink-0 transform cursor-default snap-center",
            on: "w-full flex-shrink-0 transform cursor-grab snap-center"
          }
        },
        control: {
          base: "hidden",
          icon: "hidden"
        },
        scrollContainer: {
          base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-none",
          snap: "snap-x"
        }
    };
    const handleGetSlide = async () => {
      const result = await buscarSlide();
      setSlide(result?.data)
  }
  useEffect(() => {
      handleGetSlide();
  },[])
    useEffect(() => {
        scrollToTop()
    },[window.location.pathname])
    const findBanner = () => {
      const mobile = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === true) 
      const desktop = slide?.filter((x: { isMobile: boolean; }) => x.isMobile === false) 
      return isMobile() && mobile?.length > 0 ? mobile : desktop
    }
    useEffect(() => {
      const handleScroll = () => {
        if (!load) {
          setLoad(true);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [load]);
    return (
        <div className="bg-secondary">
            <div className="h-[65vh] mobile:h-[14vh] mobile:relative">
                <Carousel slideInterval={3000} theme={findBanner().length <= 1 ? estilosHiddenControls : estilos}>
                {findBanner().length === 0 ? (
                  <div className="w-full flex justify-center">
                    <span className="loading loading-dots loading-lg"></span>
                  </div>
                ) : findBanner()?.map((x: Slider) => (
                  <img key={x.id} className='object-cover h-full w-full' src={`${process.env.REACT_APP_FOTO}${x.arquivo}`} alt="..." />
              ))}
                </Carousel>
            </div>
            <div className="flex justify-center items-center w-full">
              <div className="bg-[#cccccc] py-5 flex flex-col gap-3 items-center desktop:min-w-[1000px] shadow">
                  <h2 className="text-[#2d2b2b] font-bold text-center px-3">FAÇA SUA BUSCA E ENCONTRE AQUI SEU PRÓXIMO SEMI-NOVO</h2>
                <FiltroHome />
              </div>
            </div>
            <div className="py-8 flex flex-col justify-center items-center text-accent">
                <h1 className="font-semibold text-[#333333] text-center mobile:px-2">AUTOMÓVEIS EM DESTAQUES NA MAGUINHO AUTOMÓVEIS</h1>
            </div>
            <div className="flex flex-col gap-16 justify-center items-center pb-6 mobile:pb-2 mobile:gap-8">
              <div className="grid grid-cols-3 gap-11 mobile:grid-cols-1 px-3 mobile:gap-5 mobile:hidden">
                    {estoque?.slice(0,6).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <div className="grid grid-cols-4 px-8 gap-11 mobile:grid-cols-1 mobile:px-3 mobile:gap-5 desktop:hidden">
                    {estoque?.slice(0,3).map?.((x) => (
                        <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                    ))}
                </div>
                <button aria-label="Ver todos os veículos" onClick={() => navigate('/estoque')} className="btn btn-primary rounded-sm w-fit text-white px-32 min-h-0 h-8">ESTOQUE</button>
            </div>
                {load && (
                  <>
            <div className="flex flex-row justify-center items-center desktop:gap-16 pt-10 text-accent mobile:flex-col px-3">
                <img src={avalie} alt="Avalie" className="max-h-60 cursor-pointer" onClick={() => navigate('/avaliacao')} />
                <img src={financie} alt="Financie" className="max-h-60 cursor-pointer" onClick={() => navigate('/financiamento')} />
            </div>
            <div className="text-accent flex flex-col items-center justify-center gap-3 pt-10">
              <h1 className="font-bold text-[#333333]">NOSSA LOCALIZAÇÃO</h1>
              <h2>Faça-nos uma visita</h2>
              <iframe title='Mapa' src={Endereco.link} width="100%" height="100%" style={{border:"10px", height: "400px"}} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
                  </>
                )}
        </div>
    )
}
export default Home;