import { useContext, useEffect, useState } from "react";
import { NomeLoja } from "../shared/types";
import { InfosLoja as InfosLojaDictionary, Telefone as TelefoneDictionary, Email as EmailDictionary, NomeLoja as NomeLojaDictionary, DescLoja as DescLojaDictionary } from "../shared/utils/dictionary";
import { useNavigate } from "react-router-dom";

import logoLitoral from '../assets/logoLitoral.webp'
import { getLoja, postWhats } from "../service/api";
import { AuthContext } from "../shared/context/auth";
import { isMobile } from "../shared/utils/javascript";

export const Footer = () => {

    const { telefone, ip, infosLoja: loja, email } = useContext(AuthContext);

    const navigate = useNavigate()
    const [nomeLoja, setNomeLoja] = useState<NomeLoja>()
    const [load, setLoad] = useState<boolean>(false);

    const telefoneWhats = telefone.filter((x) => x.whatsapp === "1")
    const telefoneRamal = telefone.filter((x) => x.whatsapp === "0")

    const handleGetNomeLoja = async () => {
        try {
            const result = await getLoja()
            setNomeLoja(result?.data.retorno ?? {
                nome_empresa: ''
            }) 
        } catch {
            //
        }
    }

    useEffect(() => {
        const handleScroll = () => {
          if (!load) {
            setLoad(true);
            handleGetNomeLoja();
          }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, [load]);

    return (
        <div className="bg-black text-white mobile:mb-10">
            <div className="flex justify-center p-8 w-full mobile:px-3">
                <div className="flex justify-around gap-36 mobile:flex-col mobile:gap-10 mobile:w-full mobile:px-5">
                    <div className="flex flex-col gap-3 h-full flex-1 items-center">
                        <h2 className="self-start whitespace-nowrap text-sm">ENTRE EM CONTATO PELO TELEFONE</h2>
                        <div className="flex flex-col gap-2 self-start">
                            {telefoneWhats.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => {
                                        postWhats({
                                            ip: ip,
                                            dispositivo: isMobile() ? 'MOBILE' : 'DESKTOP',
                                            lojaId: Number(process.env.REACT_APP_COD_LOJA),
                                            codVeiculo: 0
                                        })
                                        window.open(`https://api.whatsapp.com/send?phone=+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}&text=Olá, tudo bem? Estou acessando o site e gostaria de mais informações.`, "_blank")
                                    }}>
                                    <i className="ri-whatsapp-line ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                            {telefoneRamal.map((x) => (
                                <div className="flex items-center gap-3 cursor-pointer" key={x.telefone} onClick={() => window.open(`tel:+55${x[TelefoneDictionary.TELEFONE].replace(/[( )-]/g,"")}`, "_blank")}>
                                    <i className="ri-phone-fill ri-lg"></i>
                                    <h3>{x[TelefoneDictionary.TELEFONE]}</h3>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 h-full flex-1 items-center">
                        <h2 className="self-start whitespace-nowrap text-sm">OU POR E-MAIL</h2>
                        <div className="flex flex-col gap-2 self-start">
                            <div className="flex items-center gap-3 cursor-pointer">
                                <i className="ri-mail-fill ri-lg"></i>
                                <h3 className="cursor-pointer" onClick={() => {
                                    window.open(`mailto:${email}`, "_blank")
                                }}>{email}</h3>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-3 h-full flex-1 items-center">
                        <h2 className="self-start whitespace-nowrap text-sm">HORÁRIO DE ATENDIMENTO</h2>
                        <div className="flex flex-col gap-2 self-start">
                            <h3>Segunda à Sexta:</h3>
                            <h3>{loja.loja.tbl_infosLoja[0]?.horarioSegSex}</h3>
                        </div>
                        <div className="flex flex-col gap-2 self-start">
                            <h3>Sábado:</h3>
                            <h3>{loja.loja.tbl_infosLoja[0]?.horarioSab}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-neutral py-2 flex items-center justify-center">
                {isMobile() ? (
                    <div className="flex flex-col items-center px-3">
                        <p className="flex items-center gap-2 leading-5 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por</p>
                        <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover mt-[-22px] ml-56" src={logoLitoral} alt="Litoral Car" />
                        <span onClick={() => navigate('/termos')} className="underline mt-3 cursor-pointer">Politica de privacidade e termos de uso</span>
                    </div>
                ) : (
                    <p className="flex items-center gap-2 text-xs">Copyright © 2023 Todos os direitos reservados para {nomeLoja?.[NomeLojaDictionary.NOME_LOJA]} - Desenvolvido por <img onClick={() => window.open("https://www.litoralcar.com.br/", "_blank")} className="max-h-5 cursor-pointer object-cover" src={logoLitoral} alt="Litoral Car" /> <span onClick={() => navigate('/termos')} className="underline cursor-pointer">Politica de privacidade e termos de uso</span></p>
                )}
            </div>
        </div>
    )
}