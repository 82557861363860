import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { isMobile } from '../shared/utils/javascript';
import { AuthContext } from '../shared/context/auth';

type BuscaQueryType = {
    categoria: string,
    marca: string,
    modelo: string,
    precoDe: string,
    busca: string,
    [key: string]: string;
}

export const FiltroHome = () => {

    const location = useLocation()
    const { estoque } = useContext(AuthContext)
    const navigate = useNavigate()
    const [filtroSelect, setFiltroSelect] = useState({
        categoria: "",
        marca: "",
        modelo: "",
        anoDe: "",
    });
    const [buscaQuery, setBuscaQuery] = useState<BuscaQueryType>();
    const [optionsModelo, setOptionsModelo] = useState([{value: "Modelo", label: "Modelo" }]);
    const [optionsMarca, setOptionsMarca] = useState([{value: "Marca", label: "Marca" }]);
    const [optionsValorDe, setOptionsValorDe] = useState([{value: "Ano de", label: "Ano de" }]);

    const handleNavigateFilterSelect = (selectedOption: any) => {
        const paramsObj = { 
            categoria: selectedOption.categoria === "Categoria" ? "" : selectedOption.categoria,
            marca: selectedOption.marca === "Marca" ? "" : selectedOption.marca,
            modelo: selectedOption.modelo === "Modelo" ? "" : selectedOption.modelo,
            anoDe: selectedOption.anoDe === "Ano de" ? "" : selectedOption.anoDe,
        };

        const paramsString = encodeURIComponent(JSON.stringify(paramsObj));
      
        const urlComObjeto = `/estoque?params=${paramsString}`;
        navigate(urlComObjeto)
    };

    const handleSelectChangeFiltro = async (tipo: string, value: string) => {
        setFiltroSelect(prevState => ({
            ...prevState,
            [tipo]: value
        }));
        if(tipo === "categoria") {
            setFiltroSelect(prevState => ({
                ...prevState,
                marca: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoAte: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
        }
        if(tipo === "marca") {
            setFiltroSelect(prevState => ({
                ...prevState,
                modelo: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoAte: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
        }
        if(tipo === "modelo") {
            setFiltroSelect(prevState => ({
                ...prevState,
                precoDe: ""
            }))
            setFiltroSelect(prevState => ({
                ...prevState,
                precoAte: ""
            }))
        }
    }

    const handleFilterInput = async () => {
    
        const filtroMarca = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            
            return categoriaMatch;
          });
        const marca = filtroMarca.map((x) => {
            return x.marca
        })
        const listaMarca = marca.filter((element, index) => {
            return marca.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaMarca.unshift("Marca")
        setOptionsMarca(listaMarca.map((x) => {
            return {value: x, label: x }
        }))
    
        const filtroModelo = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            
            return categoriaMatch && marcaMatch;
          });
        const modelo = filtroModelo.map((x) => {
            return x.modelo
        })
        const listaModelo = modelo.filter((element, index) => {
            return modelo.indexOf(element) === index;
        }).sort((a, b) => a.localeCompare(b));
        listaModelo.unshift("Modelo")
        setOptionsModelo(listaModelo.map((x) => {
            return {value: x, label: x }
        }))

        const filtroValorDe = estoque.filter(item => {
            const categoriaMatch = filtroSelect.categoria === '' || filtroSelect.categoria === 'Categoria' || item.categoria === filtroSelect.categoria;
            const marcaMatch = filtroSelect.marca === '' || filtroSelect.marca === 'Marca' || item.marca === filtroSelect.marca;
            const modeloMatch = filtroSelect.modelo === '' || filtroSelect.modelo === 'Modelo' || item.modelo === filtroSelect.modelo;

            return categoriaMatch && marcaMatch && modeloMatch;
          });
        const valorDe = filtroValorDe.map((x) => {
            return x.ano.toString()
        })
        const listaValorDe = valorDe.filter((element, index) => {
            return valorDe.indexOf(element) === index;
        });
        
        listaValorDe.unshift("Ano de")
        setOptionsValorDe(listaValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => {
            return {value: x, label: x }
        }))
    }
    
    useEffect(() => {
        const queryString = location.search;       
        const paramsString = new URLSearchParams(queryString).get('params');
    
        if (paramsString) {
            try {
                const paramsObj = JSON.parse(decodeURIComponent(paramsString));
                if(paramsObj.hasOwnProperty('categoria')) {
                    setBuscaQuery(paramsObj)
                }
            } catch (error) {
                //
            }
        }
    },[])
    
    useEffect(() => {
        handleFilterInput();
    },[estoque])

    useEffect(() => {
        handleFilterInput();
    },[filtroSelect])

    useEffect(() => {
        for (const propriedade in buscaQuery) {
            handleSelectChangeFiltro(propriedade, buscaQuery[propriedade])
        }
    },[buscaQuery])
    
    return (
        <div className={`text-black flex flex-col gap-5 items-center ${window.location.pathname === "/" ? "justify-center px-3 w-full" : ""}`}>
                <div className={`flex flex-row gap-5 flex-wrap ${window.location.pathname === "/" ? "justify-center w-full" : "mobile:w-full"}`}>
                    {isMobile() ? (
                        <div className='grid grid-cols-1 gap-3 justify-center w-full'>
                            <div className='flex flex-row w-full justify-around'>
                                <select value={filtroSelect.marca} onChange={(event) => handleSelectChangeFiltro('marca', event.target.value)} className="select select-bordered h-8 min-h-0 rounded-none select-radius w-full">
                                    {optionsMarca.map((x) => (
                                        <option key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                                </div>
                                <select value={filtroSelect.modelo} onChange={(event) => handleSelectChangeFiltro('modelo', event.target.value)} className="select select-bordered h-8 min-h-0 rounded-none select-radius min-w-min w-full">
                                    {optionsModelo.map((x) => (
                                        <option key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                                <div className='flex flex-row w-full justify-center gap-[inherit]'>
                                <select value={filtroSelect.anoDe} onChange={(event) => handleSelectChangeFiltro('anoDe', event.target.value)} className="select select-bordered h-8 min-h-0 rounded-none w-full select-radius">
                                    {optionsValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => (
                                        <option value={x.value} key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                                </div>
                            <div className='flex flex-row gap-5 justify-around'>
                                <button className='btn btn-primary px-8 text-white h-8 min-h-0 w-full rounded-none' onClick={() => handleNavigateFilterSelect(filtroSelect)}>Pesquisar</button>
                            </div>
                        </div>
                    ) : (
                        <div className={`flex flex-row gap-5 flex-wrap ${window.location.pathname === "/" ? "justify-center flex-col w-full" : ""}`}>
                            <div className={`${window.location.pathname === "/" ? "grid grid-cols-3 gap-5" : " flex flex-row gap-3"}`}>
                                <select value={filtroSelect.marca} onChange={(event) => handleSelectChangeFiltro('marca', event.target.value)} className="select rounded-none select-bordered h-8 min-h-0 min-w-52 select-radius">
                                    {optionsMarca.map((x) => (
                                        <option key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                                <select value={filtroSelect.modelo} onChange={(event) => handleSelectChangeFiltro('modelo', event.target.value)} className="select rounded-none select-bordered h-8 min-h-0 min-w-52 select-radius">
                                    {optionsModelo.map((x) => (
                                        <option key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                                <select value={filtroSelect.anoDe} onChange={(event) => handleSelectChangeFiltro('anoDe', event.target.value)} className="select rounded-none select-bordered h-8 min-h-0 min-w-40 select-radius">
                                    {optionsValorDe.sort((a,b) => Number(a) - Number(b)).map((x) => (
                                        <option value={x.value} key={x.value}>{x.label}</option>
                                    ))}
                                </select>
                                <button className={`btn btn-primary rounded-none px-8 h-8 min-h-0 col-start-3 text-white ${window.location.pathname === "/" ? "w-full" : ""}`} onClick={() => handleNavigateFilterSelect(filtroSelect)}>Pesquisar</button>
                            </div>
                        </div>
                    )}
                </div>
        </div>
    )
}