import { SetStateAction, useContext, useEffect, useState } from "react"
import { FiltroEstoque } from "../components/filtroEstoque"
import { Veiculo } from "../shared/types";
import { CardVeiculo } from "../components/cardVeiculo";
import { isMobile, scrollToTop } from "../shared/utils/javascript";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../shared/context/auth";

type BuscaType = {
    categoria: string,
    marca: string,
    modelo: string,
    anoDe: string,
    busca: string,
}

export const Estoque = () => {

    const location = useLocation();
    const { estoque } = useContext(AuthContext)
    const [estoqueFiltrado, setEstoqueFiltrado] = useState<Veiculo[]>([]);
    const [busca, setBusca] = useState<BuscaType>();

    const itensPorPagina = 12;
    const [paginaAtual, setPaginaAtual] = useState(1);
    const indiceInicio = (paginaAtual - 1) * itensPorPagina;
    const indiceFim = indiceInicio + itensPorPagina;
    const itensDaPagina = estoqueFiltrado.slice(indiceInicio, indiceFim);

    const numeroDePaginas = Math.ceil(estoqueFiltrado.length / itensPorPagina);

    const handleClickPagina = (novaPagina: SetStateAction<number>) => {
        setPaginaAtual(novaPagina);
        scrollToTop();
    };
    const handleFilterEstoque = () => {
        setEstoqueFiltrado(estoque)
        if(busca?.hasOwnProperty("busca")) {
            const filtroEstoqueMarca = estoque.filter(item => item.marca === busca.busca)
            const filtroEstoqueModelo = estoque.filter(item => item.modelo === busca.busca)
            setEstoqueFiltrado(filtroEstoqueMarca.length === 0 ? filtroEstoqueModelo : filtroEstoqueMarca)
        } else if(busca === undefined) {
            setEstoqueFiltrado(estoque)
        } else {
            const filtroEstoque = estoque.filter(item => {
                const categoriaMatch = busca?.categoria === '' || item.categoria === busca?.categoria;
                const marcaMatch = busca?.marca === '' || item.marca === busca?.marca;
                const modeloMatch = busca?.modelo === '' || item.modelo === busca?.modelo;
                const valorDeMatch = busca?.anoDe === '' || item.ano >= Number(busca?.anoDe);
    
                return categoriaMatch && marcaMatch && modeloMatch && valorDeMatch;
              });
              let estoqueFiltradoOrdenado = [...filtroEstoque];
              if (busca?.anoDe !== "") {
                  estoqueFiltradoOrdenado.sort((a, b) => a.ano - b.ano);
              }
              setEstoqueFiltrado(estoqueFiltradoOrdenado);
        }
    }

    useEffect(() => {
        handleFilterEstoque();
        scrollToTop()
    },[busca, estoque]) 

    useEffect(() => {
        const queryString = window.location.search;       
        const paramsString = new URLSearchParams(queryString).get('params');
    
        if (paramsString) {
            try {
                const paramsObj = JSON.parse(decodeURIComponent(paramsString));
                setBusca(paramsObj)
            } catch (error) {
                //
            }
        }
    }, [location.search]);

    return (
        <div className="px-28 bg-secondary py-16 flex flex-col gap-8 mobile:px-3 mobile:gap-5">
            {/* Filtro */}
            <div className="flex flex-row items-center gap-3">
                <h1 className="min-w-max text-accent font-semibold">NOSSO ESTOQUE</h1>
                <hr className="h-0.5 bg-accent w-full mobile:w-full" />
            </div>
            <div className="flex items-center gap-3">
                {isMobile() ? null : (
                    <FiltroEstoque />
                    )}
            </div>
            {isMobile() ? (
                <FiltroEstoque />
            ) : null}

            {/* Estoque */}
            <div className="grid grid-cols-4 gap-11 mobile:mt-5 mobile:flex mobile:flex-col">
                {itensDaPagina.map((x) => (
                    <CardVeiculo key={x.cod_veiculo} veiculo={x} />
                ))}
            </div>
            
            {/* Paginação */}
            <div className="flex flex-row mt-4 items-center justify-center">
                <i className="ri-arrow-left-s-line mr-2 cursor-pointer" onClick={() => {
                    if(paginaAtual === 1) return
                    handleClickPagina(paginaAtual - 1)
                }}></i>
                {Array.from({ length: numeroDePaginas }, (_, index) => (
                    <button
                    key={index + 1}
                    className={`px-3 text-accent py-1 ${paginaAtual - 4 > index ? "hidden" : ""} ${paginaAtual + 2 < index ? "hidden" : ""} ${paginaAtual === index + 1 ? 'border border-accent font-semibold' : 'text-accent'}`}
                    onClick={() => handleClickPagina(index + 1)}
                    >
                    {index + 1}
                </button>
                ))}
                <i className="ri-arrow-right-s-line ml-2" onClick={() => {
                    if(paginaAtual === numeroDePaginas) return
                    handleClickPagina(paginaAtual + 1)
                }}></i>
            </div>
        </div>
    )
}