import { useEffect, useState } from 'react'

import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { HeaderAdmin } from './headerAdmin';
import { AsideAdmin } from './asideAdmin';
import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Title } from 'chart.js';
import { getWhatsApp } from '../../service/api';
import { Whatsapp } from '../../shared/types';
import { Line } from 'react-chartjs-2';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Title);

interface GroupedData {
    [date: string]: number;
  }

export const WhatsappAdmin = () => {

    const [whatsapp, setWhatsapp] = useState<Whatsapp>()
    const [data, setData] = useState<any>({
        labels: [""],
        datasets: [
          {
            label: 'Quantidade de Cliques por Dia',
            data: [""],
            fill: false,
            borderColor: 'rgb(75, 192, 192)',
            tension: 0.1,
          },
        ],
      })
    const navigate = useNavigate()
    
    useEffect(()=> {
        const loginCookies = Cookies.get('token');
        if(loginCookies === undefined) {
            navigate('/admin')
        }
        return
    },[navigate])

    const buscaAcessos = async () => {
        try {
            const acessos = await getWhatsApp()
            setWhatsapp(acessos?.data)
        } catch (error) {
            //
        }
    }

    useEffect(() => {
        buscaAcessos();
    },[])

    useEffect(() => {
        if (!whatsapp?.whatsappCliques || !Array.isArray(whatsapp.whatsappCliques || whatsapp === undefined)) {
            // Se whatsapp for undefined ou não for uma array, saia da função
            return;
        }
        // Processamento dos dados para agrupar por dia e contar cliques
        const groupedData = whatsapp.whatsappCliques.reduce((acc: GroupedData, entry) => {
            const date = entry.data_hora.split('T')[0]; // Obtém a parte da data
            if (acc[date]) {
                acc[date] += 1; // Conta cliques por dia
            } else {
                acc[date] = 1;
            }
            return acc;
        }, {} as GroupedData) || {}; // Use {} se whatsapp for undefined
            
        const labels = Object.keys(groupedData);
        const values = Object.values(groupedData);
    
        const chartData = {
          labels: labels,
          datasets: [
            {
              label: 'Quantidade de Cliques por Dia',
              data: values,
              fill: false,
              borderColor: "rgb(75, 192, 192)",
              tension: 0.1
            },
          ],
        };
    
        setData(chartData);
      }, [whatsapp]);

    return (
        <div>
            <div className='flex flex-col h-full fixed w-full z-0'>
                <HeaderAdmin />
                <AsideAdmin />
            </div>
            <div className='p-28'>
                <div className='flex items-center gap-3'>
                    <i className="ri-dashboard-3-line ri-3x"></i>
                    <h1 className='font-semibold text-2xl'>Whatsapp</h1>
                </div>
                <hr className='w-full h-px' />
                <div className='z-10 relative'>
                    <div className='flex mt-5 items-center gap-2 ml-5 mb-2'>
                        <i className="ri-whatsapp-line ri-2x"></i>
                        <h3 className='text-lg font-semibold'>Cliques no whatsapp</h3>
                    </div>
                    <div className='rounded-lg p-5 border-slate-400 border-8'>
                        <Line style={{maxHeight: 300}} data={data}/>
                    </div>
                </div>
            </div>
        </div>
    )
}